import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { MainContainer } from '../../components/Container';
import {
    PageTitle, MainText
} from '../../components/Text';
import Layout from '../../components/Layout';

const MainContainerStyled = styled(MainContainer)`
  h4 {
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    font-size: 24px;
  }

  a {
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    text-decoration: none;
    transition: 0.2s;
    font-weight: bold;

    &:hover {
      color: rgba(${({ theme }) => theme.colors.mainBlue.rgb}, 0.8);
    }
  }
`;

const Terms = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <Helmet>
                <title>
                    {t('terms_meta_title')}
                </title>
                <meta name="description"
                    content={t('terms_meta_description')} />
            </Helmet>
            <MainContainerStyled>
                <PageTitle>
                    {t('terms')}
                </PageTitle>
                <MainText dangerouslySetInnerHTML={{ __html: t('terms_text') }} />
            </MainContainerStyled>
        </Layout>
    );
};

export default React.memo(Terms);
